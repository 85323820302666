<template>
  <main id="home-screen" class="main">
    <div class="breadcrumb">
      <img
        src="../assets/images/punta-braccetto.jpg"
        alt="immagine-home"
        height="auto"
        width="100%"
      />
    </div>

    <div class="container">
      <h2 class="text-center mb-4">
        {{ __("BENVENUTI / WELCOME / WILLKOMMEN / BIENVENUE") }}
      </h2>

      <router-link
        to="/info"
        class="box-light text-decoration-none justify-content-center mb-4"
      >
        <div class="inner">
          <i class="fa fa-home"></i>
          <span class="text">{{ __("Informazioni della struttura") }} </span>
        </div>
      </router-link>

      <router-link
        to="/bar"
        class="box-light text-decoration-none justify-content-center mb-4"
      >
        <div class="inner">
          <i class="fa fa-cocktail"></i>
          <span class="text">{{ __("Bar") }} </span>
        </div>
      </router-link>

      <router-link
        to="/ristorante"
        class="box-light text-decoration-none justify-content-center mb-4"
      >
        <div class="inner">
          <i class="fa fa-utensils"></i>
          <span class="text">{{ __("Ristorante") }} </span>
        </div>
      </router-link>

      <router-link
        to="/eventi"
        class="box-light text-decoration-none justify-content-center mb-4"
      >
        <div class="inner">
          <i class="fa fa-calendar"></i>
          <span class="text">{{ __("Eventi") }} </span>
        </div>
      </router-link>

      <router-link
        to="/territorio"
        class="box-light text-decoration-none justify-content-center mb-4"
      >
        <div class="inner">
          <i class="fa fa-map-pin"></i>
          <span class="text">{{ __("Visita il territorio") }} </span>
        </div>
      </router-link>

      <router-link
        to="/servizi"
        class="box-light text-decoration-none justify-content-center"
      >
        <div class="inner">
          <i class="fa fa-bell-concierge"></i>
          <span class="text">{{ __("Servizi") }} </span>
        </div>
      </router-link>
    </div>
  </main>
</template>

<script>
import { Utils } from "@/inc/Utils";

export default {
  name: "Home",
  mixins: [Utils],
};
</script>
<style scoped>
h2 {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;

  color: #deab4e;
}
a.box-light .inner {
  min-width: 230px;
}
a.box-light .text {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-left: 10px;
}
</style>